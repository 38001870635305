import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'
import './App.css'

const imgURLs = [
  '47372448-8053-4d4a-9cfb-95565a36deda', //1.png
  '49c151c0-953b-48cd-9193-ec26851354db', //2.png
  '8178efa2-53ca-4d9d-a859-d75c9577b3dd', //bg.png
]

function Landing1(props: any) {
  const [img1, setImg1] = useState('')
  const [img2, setImg2] = useState('')
  const [bg, setBG] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (props?.dl?.length) {
      const cdn = props.dl[0]
      Promise.all(
        imgURLs.map((url) => fetch(`https://${cdn}/images/${url}`))
      ).then(async (res) => {
        for (let i = 0; i < res.length; i++) {
          let str = await res[i].text()
          str = getSrc(str)
          if (i === 0) setImg1(str)
          else if (i === 1) setImg2(str)
          else if (i === 2) setBG(str)
        }
        setIsLoading(false)
      })
    }
  }, [])

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className="App m-auto md:max-w-[640px]"
      style={{ backgroundImage: `url(data:image/png;base64,${bg})` }}
      onClick={onClickDownload}>
      <header className="App-header">
        <div
          style={{
            width: 375,
            paddingRight: 20,
            marginBottom: 12,
            display: 'flex',
            justifyContent: 'flex-end',
          }}></div>
        <img
          alt=""
          src={`data:image/png;base64,${img1}`}
          style={{ cursor: 'pointer', marginBottom: 29, width: '90%' }}
        />
        <div className="Rectangle">立即安装</div>
        <img
          alt=""
          src={`data:image/png;base64,${img2}`}
          style={{ marginBottom: 150, width: '92%' }}
        />
      </header>
    </div>
  )
}

export default Landing1
