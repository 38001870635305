import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'

// p2
const imgURLs = [
  // 01
  '553ef7d7-df8f-478b-ab62-878ff4d937bf',
  // Footer
  '9b2c9ed7-3e71-4652-932a-44962c1959bc',
  // Logo
  '59b7b3a0-c42a-4cf6-bc01-43a85eb3f151',
]

function Landing2(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className="m-auto flex flex-col md:max-w-[640px]"
      onClick={onClickDownload}>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[0]}`}
        className="mb-9 w-full"
      />
      {/* footer */}
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[1]}`}
        className="sticky bottom-0 w-full"
      />
    </div>
  )
}

export default Landing2
