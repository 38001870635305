import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'

// p1
const imgURLs = [
  // 01
  'a5aef81d-9cd9-47db-8c65-ab6ffdc2a658',
  // Footer
  '6b5d1db1-ef17-4d94-9941-a1ffdaa055fc',
  // Logo
  '547fc98b-e25a-4c8a-aff6-5ced39a0058b',
]

function Landing3(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div
      className="m-auto flex flex-col md:max-w-[640px]"
      onClick={onClickDownload}>
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[0]}`}
        className="mb-9 w-full"
      />
      {/* footer */}
      <img
        alt=""
        src={`data:image/gif;base64,${imgArr[1]}`}
        className="sticky bottom-0 w-full"
      />
    </div>
  )
}

export default Landing3
