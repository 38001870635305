import { useEffect, useState } from 'react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'
import './App.css'

const imgURLs = [
  // 01
  '8d34dd36-ec9c-4407-9abe-32217b49828b',

  // 02
  'd594fd9b-7a7e-439f-acc3-aeb019bd5589',

  // 03
  'fd123766-5d89-4343-a122-6cbe7a38436f',

  // logo
  '54b73127-b36d-4916-a996-fb19d3da9ca3',

  // banner
  'fe1614e6-1053-4a98-9097-d1bf821f2d48',

  // footer
  'bb82348d-38d0-48f5-90bc-3ff06f49d3f7',
]

function Landing4(props: { dl: string | string[] }) {
  const [imgArr, setImgArr] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!props.dl.length) return
    const cdn = props.dl[0]
    const result: string[] = []

    async function getAllImg() {
      for (let i = 0; i < imgURLs.length; i++) {
        const res = await fetch(`https://${cdn}/images/${imgURLs[i]}`)
        const str = await res.text()
        result.push(getSrc(str))
      }
      setImgArr(result)
      setIsLoading(false)
    }
    getAllImg()
  }, [props.dl])

  if (imgArr.length < 0) return null

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="App m-auto bg-[#001a40] md:max-w-[640px]">
      <div
        className="flex flex-col items-center justify-center"
        onClick={onClickDownload}>
        <div className="sticky top-0 z-10 flex w-full items-center justify-between bg-[#444] bg-gradient-to-r py-5 px-4">
          <img
            alt=""
            src={`data:image/gif;base64,${imgArr[3]}`}
            className="w-[30%]"
          />
          <div className="flex h-8 items-center justify-center rounded-2xl bg-[#ffb922] px-3 py-1 text-sm font-semibold text-[#000]">
            立即下载
          </div>
        </div>
        <img
          alt=""
          className="w-full bg-[#444] px-4 pb-6"
          src={`data:image/gif;base64,${imgArr[4]}`}
        />
        <img
          alt=""
          className="w-full"
          src={`data:image/gif;base64,${imgArr[0]}`}
        />
        <img
          alt=""
          className="mt-7 w-full"
          src={`data:image/gif;base64,${imgArr[1]}`}
        />
        <img
          alt=""
          className="mt-7 w-full"
          src={`data:image/gif;base64,${imgArr[2]}`}
        />
        <img
          alt=""
          src={`data:image/gif;base64,${imgArr[5]}`}
          className="sticky bottom-0 w-full bg-transparent"
        />
      </div>
    </div>
  )
}

export default Landing4
